<template>
   <div class="house-navgation-wrapper flex-box-column">
        <div class="hd">
            <van-nav-bar left-arrow :title="pageTitle" :border="false" @click-left="back"></van-nav-bar>
        </div>
        <div class="bd flex-box-column">
            <div class="house-navgation-container" id="container"></div>
            <div class="refresh-map" @click="resetMap" v-if="type == 1"><van-icon name="system-refresh" /></div>
        </div>
   </div>
</template>

<script>
import mapLoad from "@/utils/Amap";
import backMixin from "@/mixins/back";
export default {

    mixins:[backMixin],


    data(){

        return{

            pageTitle:'',

            inited:false,

            AMap:null,

            point:null,

            map:null,

            type:1

        }

    },


    methods:{

        /**
         * 
         * 地图初始化
         * 
         */

        init(point,type){

            if(!point) return;

            this.inited=false;

            let plugins = ['AMap.ToolBar'];

            this.type= type;

            if(type == 1){

                plugins.push('AMap.Driving','AMap.Geocoder');

            }

            //检查一下是啥类型 type 1 为导航 ， 2为周边地图，周边一定会出现 typeid 代表对应的周边;
            
            mapLoad(plugins).then((AMap)=>{

                this.AMap = AMap;

                this.AMap.convertFrom(point, 'baidu', (status, result)=>{
                            
                    if (result.info === 'ok') {

                                var lnglats = result.locations; // Array.<LngLat>
                                
                                if(lnglats.length == 0) return;

                                this.point = new this.AMap.LngLat(lnglats[0].lng, lnglats[0].lat);

                                let marker = new this.AMap.Marker({
                                    position:this.point,
                                    title: ''
                                });

                                this.map = new this.AMap.Map('container', {

                                    zoom:12,//级别
                                    center: this.point,//中心点坐标
                                    resizeEnable: true,
                    
                                });

                                this.map.addControl(new this.AMap.ToolBar({

                                    liteStyle: true

                                }));

                                this.map.add(marker);


                                //如果是导航地图

                                if(type == 1){

                            
                                    this.accessToLocate();
                                    

                                }

                            }

                });
        

            });

        },

        /**
         * 
         * 发起定位
         * 
         */

        accessToLocate(){
            
            this.inited = false;

            this.AMap.plugin('AMap.Geolocation', ()=> {
                           
                var geolocation = new this.AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 20000,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonOffset: new this.AMap.Pixel(10, 20),
                     //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,     
                    //  定位按钮的排放位置,  RB表示右下
                    buttonPosition: 'RB'
                })

                                    this.$toast.loading({

                                        duration:0,

                                        message:'正在获取您的位置...'

                                    })

                                    geolocation.getCurrentPosition((status,result)=>{

                                            if(!this.$route.name || this.$route.name!='houseNavigation') return;

                                            if(status=='complete'){

                                                onComplete(result);

                                            }else{

                                                onError(result);

                                            }

                                    });

                                    const onComplete = (data)=> {

                                        this.$toast.loading({

                                            duration:0,

                                            message:'获取成功，开始生成路线规划'

                                        });
                                        
                                        //定位成功后进行操作

                                        const driving = new this.AMap.Driving({
                                            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
                                            policy: this.AMap.DrivingPolicy.LEAST_TIME,
                                            map:this.map
                                        });

                                        driving.search(data.position,this.point, (status, result)=>{

                                            // 未出错时，result即是对应的路线规划方案

                                            this.$toast(`路线规划生成完毕。\n${data.message.indexOf('Get ipLocation success') !=-1 ? '如有偏差，请开启手机定位功能': ''}`);

                                            this.inited=true;


                                        });

                                    }

                                    const onError = (data)=> {

                                        // 定位出错

                                        this.$toast('定位失败，请检查是否开启定位功能，然后点击右下角刷新按钮重试');

                                        this.inited=true;

                                    }
            })

        },


        //重置地图

        resetMap(){

            if(!this.inited) return;

            this.accessToLocate();

        }

    },


    mounted(){

        this.pageTitle = this.$route.query.title;

        global.setTimeout(()=>{

            //必须有传参

            this.init(this.$route.query.point,this.$route.query.type);

        },400);


    },


    beforeDestroy(){

        this.$toast.clear();

    }

}
</script>

<style scoped lang="less">
.house-navgation-wrapper{

    width: 100%;

    height:100%;


    .bd{

        flex:1 1 auto;

        .house-navgation-container{

            flex:1 1 auto;

        

        }

            position: relative;

            .refresh-map{

                position: absolute;

                z-index:40;

                right:14px;

                bottom:120px;

                border:5px solid #fff;

                background: #fff;

                box-shadow: 0 5px 16px 0 rgba(0,0,0,.16);

                border-radius: 50%;

                i{

                    font-size:32px;

                    color:#00a0e4;

                }

            }

    }

}
</style>
